import { reinitializeComponent } from "./utils/render.js";

// Array of Vue Models of the NewsletterSubscription instances
const vmNewsletterSubscription = [];

// Function used to initialize a single Vue instance
const initializeSingleNewsletterSubscription = (element, index) => {
  vmNewsletterSubscription[index] = new Vue({
    el: `#${element.id}`,
    data: {
      vname: null,
      vlastName: null,
      vemail: null,

      nameError: false,
      lastNameError: false,
      emailError: false,
    },
    methods: {
      checkform (event) {
        if(this.vname && this.vlastName && this.vemail) {
          return true;
        }
        this.nameError = false;
        this.lastNameError = false;
        this.emailError = false;

        if(!this.vname) {
          this.nameError = true;
        }
        if(!this.vlastName) {
          this.lastNameError = true;
        }
        if(!this.vemail) {
          this.emailError = true;
        }
        event.preventDefault();
      }
    },
  });
}

// Function used to initialize the Vue JS components
const initializeNewsletterSubscription = (event) => {
  const formComponent = document.querySelectorAll(".newsletter-subscription__container");

  //initialize media-container Vue JS instances
  formComponent.forEach((element, index) => {
    initializeSingleNewsletterSubscription(element, index)
  });
};

// When the page DOM is loaded proceed to initialize the Vue Model instance for the component
window.addEventListener("DOMContentLoaded", initializeNewsletterSubscription);

// Listener to catch the custom event triggered when the component is reloaded in the authoring page of BR
// When this event is triggered the array of Vue Models of the component in the page are destroyed and reinitialized
document.addEventListener("brContainerRedraw-newsletterSubscription", (e) => {
  reinitializeComponent(e.componentId, vmNewsletterSubscription, initializeSingleNewsletterSubscription);
});
