import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import { reinitializeComponent } from "./utils/render";

// Array of Vue Models of the Product Line Carousel instances
const vmProductLineCarousel = [];

const initializeSingleProductLineCarousel = (element, index) => {
    vmProductLineCarousel[index] = new Vue({
        el: element,
        mounted: function () {
            // Get the number of cards and divide it by 2 to get the inital card index at the middle
            const numberOfCards = element.querySelectorAll(".glide__slide").length;
            const middleIndex = parseInt(numberOfCards / 2, 10);

            // Creating a new Glide Instance with predefined parameters
            const glide = new Glide(this.$el, {
                type: 'slider',
                startAt: middleIndex,
                perView: 3,
                animationDuration: 400,
                focusAt: 'center',
                gap: 16,
                breakpoints: {
                    1023: {
                        perView: 1,
                        peek: {
                            before: 197,
                            after: 197
                        },
                    },
                    767: {
                        perView: 1,
                        peek: {
                            before: 4,
                            after: 4,
                        },
                    }
                },
            });

            // Creating a custom component for the Glide instance, with the goal of accessing the cards html to add custom class
            const CustomActiveClass = function (Glide, Components, Events) {
                const CustomComponent = {
                    mount() {
                        // When the Glide Instance mounts this function should be executed to provide the custom class since the first load
                        this.changeActiveSlide();
                    },

                    // Custom function that takes care of adding custom active class to the next card in the carousel
                    changeActiveSlide() {
                        const nextSlide = Components.Html.slides[Glide.index];
                        // Erase the custom active class from all items on the carosuel
                        Components.Html.slides.forEach(slide => slide.classList.remove('custom-active-card'))
                        // Apply the custom class just to the next slide selected
                        nextSlide.classList.add('custom-active-card');
                    },
                };
                // Add event listener to execute the desired function when the target card index has been calculated
                Events.on('run', () => {
                    CustomComponent.changeActiveSlide();
                });
                // Return the custom component object
                return CustomComponent;
            };

            // Mount the Glide instance with the custom component added to it
            glide.mount({Controls, Breakpoints, Swipe, 'CustomActiveClass': CustomActiveClass})
        }
    });
}

// Function used to initialize the Vue JS components
const initializeProductLineCarousel = (event) => {
    const productLineCarousel = document.querySelectorAll('.product-line-carousel');

    productLineCarousel.forEach((element, index) => {
        initializeSingleProductLineCarousel(element, index);
    });
};

// When the page DOM is loaded proceed to initialize the Vue Model instance for the component
window.addEventListener("DOMContentLoaded", initializeProductLineCarousel);

// Listener to catch the custom event triggered when the component is reloaded in the authoring page of BR
// When this event is triggered the array of Vue Models of the component in the page are destroyed and reinitialized
document.addEventListener("brContainerRedraw-productLineCarousel", (e) => {
    reinitializeComponent(e.componentId, vmProductLineCarousel, initializeSingleProductLineCarousel);
});