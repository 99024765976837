import { reinitializeComponent } from "./utils/render";

// Array of vue media container instances
const vmFAQs = [];

// Function used to initialize a single Vue instance
const initializeSingleFAQs = (element, index) => {
  vmFAQs[index] = new Vue({
    el: `#${element.id}`,
    data: {},
    methods: {
      searchSpecificFAQ: function () {
        // Get id in the query string parameters of the URL
        const urlString = window.location.href;
        const url = new URL(urlString);
        const idParam = url.searchParams.get("faq");

        // If there's a faq ID scroll to its location and click it
        if (idParam) {
          const faqButtonSelector = `#${element.id} #${element.id}-faq-accordion > #${idParam} button`;
          const faqTabSelector = `#${element.id} #${element.id}-faq-accordion > #${idParam}`;

          const tabYCoordinate = document
              .querySelector(faqTabSelector)
              .getBoundingClientRect().top;

          window.scrollTo({
            top: tabYCoordinate - 120,
            left: 0,
            behavior: "smooth",
          });

          document.querySelector(faqButtonSelector).click();
        } else {
          return;
        }
      },
    },
    mounted: function () {
      // Look if there's a default tab to open when the component is mounted
      this.searchSpecificFAQ();
    },
  });
}

// Function used to initialize the Vue JS component
const initializeFAQs = (event) => {
  const faqComponents = document.querySelectorAll(".faq__outer-container");

  //initialize media-container Vue JS instances
  faqComponents.forEach((element, index) => {
    initializeSingleFAQs(element, index);
  });
};

// When the page DOM is loaded proceed to initialize the Vue Model instance for the component
window.addEventListener("DOMContentLoaded", initializeFAQs);

// Listener to catch the custom event triggered when the component is reloaded in the authoring page of BR
// When this event is triggered the array of Vue Models of the component in the page are destroyed and reinitialized
document.addEventListener("brContainerRedraw-faq", (e) => {
  reinitializeComponent(e.componentId, vmFAQs, initializeSingleFAQs);
});
