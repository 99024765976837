import { reinitializeComponent } from "./utils/render";

// Array of Vue Models of the Tabbed Sections instances
const vmTabbedSections = [];

// Function used to initialize a single Vue instance
const initializeSingleTabbedSections = (element, index) => {
  vmTabbedSections[index] = new Vue({
    el: `#${element.id}`,
    data: {
      showTabbedSections: [],
    },
    methods: {
      countTabbedSections: function () {
        // Get the number of tabbed sections inside one vue instance of this type of component
        const tabbedSections = document.querySelectorAll(
            `#${element.id} > .tabbed-sections__inner-container .tabbed-sections__tab-container`
        );

        // Fill the array to keep track of opened and closed tabs
        for (let i = 0; i < tabbedSections.length; i++) {
          let tabObj = {
            id: `${element.id}-ts-${i + 1}`,
            toggle: false,
            tabId: tabbedSections[i].getAttribute("data-tab-section-id")
          };
          this.showTabbedSections.push(tabObj);
        }
      },
      toggleTabbedSection: function (event) {
        const tabId = event.target.getAttribute("data-tab-section-id");
        const currentTabOffset = this.getTabOffset(tabId);
        this.showTabbedSections.forEach((tabbedSection) => {
          if (tabbedSection.tabId === tabId) {
            if (tabbedSection.toggle === true) {
              // If tab open then close it
              tabbedSection.toggle = false;
            } else {
              // If tab is closed, then open it and scroll to it
              tabbedSection.toggle = true;
              this.scrollToTab(tabbedSection.tabId, "instant", currentTabOffset);
            }
          } else {
            // Close all the tabs that aren't the one clicked
            tabbedSection.toggle = false;
          }
        });
      },
      getTabOffset: function(tabId) {
        const tabButtonSelector = `#${element.id} > .tabbed-sections__inner-container .tabbed-sections__tab-container[data-tab-section-id="${tabId}"] button`;
        const tabsHolderSelector = `#${element.id} > .tabbed-sections__inner-container .tabbed-sections__tabs-holder`;

        return {
          mobile: document.querySelector(tabButtonSelector)
              .getBoundingClientRect().top,
          desktop: document.querySelector(tabsHolderSelector)
              .getBoundingClientRect().top
        };
      },
      scrollToTab: function (tabId, scrollBehavior, previousOffset = {mobile: 105, desktop: 140}) {
        //Scroll logic is done after rendering tab toggle to accurately calculate scroll needed
        this.$nextTick(() => {
          const viewportWidth = window.innerWidth;
          const currentPosition = this.getTabOffset(tabId);

          // If device is desktop scroll to the tabs holder, otherwise scroll to tab button
          // topOffset = absolute y position of screen + relative y position of element - offset
          const topOffset = viewportWidth < 1024
              ? window.scrollY + currentPosition.mobile - previousOffset.mobile
              : window.scrollY + currentPosition.desktop - previousOffset.desktop;

          window.scrollTo({
            top: topOffset,
            left: 0,
            behavior: scrollBehavior,
          });
        });
      },
      openSpecificTab: function (idParam) {
        // Search for tab id within registered tabs
        const tabIndex = this.showTabbedSections.findIndex((tab) => tab.tabId === idParam);

        // Check if the url id is valid to scroll and click
        if (tabIndex !== -1) {
          this.showTabbedSections[tabIndex].toggle = true;
          this.scrollToTab(idParam, "smooth");
        } else {
          // If the url id is invalid then open the first tab section
          this.showTabbedSections[0].toggle = true;
        }
      },
    },
    created: function () {
      // When component html loads count the number of tabbed sections on it
      this.countTabbedSections();
    },
    mounted: function () {
      // Get id in the query string parameters of the URL
      const urlString = window.location.href;
      const url = new URL(urlString);
      const idParam = url.searchParams.get("section");

      // Look if there's a default tab to open when the component is mounted
      if (idParam) {
        this.openSpecificTab(idParam);
      } else {
        // If not then open the first tab when component is mounted
        this.showTabbedSections[0].toggle = true;
      }
    },
  });
}

// Function used to initialize the Vue JS components
const initializeTabbedSections = (event) => {
  const tabbedSectionsComponents = document.querySelectorAll(
    ".tabbed-sections__outer-container"
  );

  //initialize tabbed-sections Vue JS instances
  tabbedSectionsComponents.forEach((element, index) => {
    initializeSingleTabbedSections(element, index);
  });
};

// When the page DOM is loaded proceed to initialize the Vue Model instance for the component
window.addEventListener("DOMContentLoaded", initializeTabbedSections);

// Listener to catch the custom event triggered when the component is reloaded in the authoring page of BR
// When this event is triggered the array of Vue Models of the component in the page are destroyed and reinitialized
document.addEventListener("brContainerRedraw-tabbedSections", (e) => {
  reinitializeComponent(e.componentId, vmTabbedSections, initializeSingleTabbedSections);
});
