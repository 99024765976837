import Glide, {
  Controls,
  Breakpoints,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";
import { reinitializeComponent } from "./utils/render.js";

// Array of Vue Models of the Image Card Carousel instances
const vmImageCardCarousel = [];

// Function used to initialize a single Vue instance
const initializeSingleImageCardCarousel = (element, index) => {
  vmImageCardCarousel[index] = new Vue({
    el: element,
    mounted: function () {
      new Glide(this.$el, {
        type: "carousel",
        startat: 0,
        perView: 3,
        focusAt: 0,
        gap: 16,
        peek: {
          before: 4,
          after: 4,
        },
        breakpoints: {
          1023: {
            perView: 2,
            peek: {
              before: 4,
              after: 160,
            },
          },
          767: {
            perView: 1,
            peek: {
              before: 4,
              after: 4,
            },
          },
        },
      }).mount({ Controls, Breakpoints, Swipe });
    },
  });
}

// Function used to initialize the Vue JS components
const initializeImageCardCarousel = (event) => {
  const imageCardCarousel = document.querySelectorAll(
    ".image-card-carousel-wrapper"
  );

  imageCardCarousel.forEach((element, index) => {
    initializeSingleImageCardCarousel(element, index);
  });
};

// When the page DOM is loaded proceed to initialize the Vue Model instance for the component
window.addEventListener("DOMContentLoaded", initializeImageCardCarousel);

// Listener to catch the custom event triggered when the component is reloaded in the authoring page of BR
// When this event is triggered the array of Vue Models of the component in the page are destroyed and reinitialized
document.addEventListener("brContainerRedraw-imageCardCarousel", (e) => {
  reinitializeComponent(e.componentId, vmImageCardCarousel, initializeSingleImageCardCarousel);
});
