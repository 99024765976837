const vmHeader = new Vue({
  el: "#gdx-header",
  data: {
    showNavMenu: false,
    showSearchBar: false,
    showRegionSelector: false,
    showLoginLinks: false,
    secondNavMenu: [],
    searchInput: "",
    animationDuration: 300,
    viewportWidth: 0,
  },
  methods: {
    countSecondNavLinks: function () {
      const numberOfSubLinks = document.querySelectorAll(
        ".second-nav__container"
      ).length;

      for (let i = 0; i < numberOfSubLinks; i++) {
        let linkObj = {
          id: `sn-${i + 1}`,
          toggle: false,
        };
        this.secondNavMenu.push(linkObj);
      }
    },
    toggleNavMenu: function (event, closeFlag) {
      if (this.showNavMenu === true || closeFlag === true) {
        // If window open or rquested close, then close all tabs
        this.toggleLoginLinks(null, true);
        this.showSearchBar = false;
        this.toggleRegionSelector(null, true);
        this.toggleSecondNavLink(null, true);
        // Soft animated close of the present window
        this.$refs.navFirstLevelCnt.classList.remove("expand");
        setTimeout(() => (this.showNavMenu = false), this.animationDuration);
      } else {
        // If window is closed, then open with animation
        this.showNavMenu = true;
        setTimeout(
          () => this.$refs.navFirstLevelCnt.classList.add("expand"),
          10
        );
      }
    },
    toggleSearchBar: function (event, closeFlag) {
      if (this.showSearchBar === true || closeFlag === true) {
        // Soft animated close of the present window
        this.$refs.searchSecondLevelCnt.classList.remove("expand");
        setTimeout(() => {
          this.showSearchBar = false;
        }, this.animationDuration);
      } else {
        // Before open close all other tabs
        this.toggleLoginLinks(null, true);
        this.toggleRegionSelector(null, true);
        this.toggleSecondNavLink(null, true);
        // If window is closed, then open with animation
        this.showSearchBar = true;
        setTimeout(
          () => this.$refs.searchSecondLevelCnt.classList.add("expand"),
          10
        );
      }
    },
    toggleRegionSelector: function (event, closeFlag) {
      if (this.showRegionSelector === true || closeFlag === true) {
        // Soft animated close of the present window
        this.$refs.regionSecondLevelCnt.classList.remove("expand");
        setTimeout(
          () => (this.showRegionSelector = false),
          this.animationDuration
        );
      } else {
        // Before open close all other tabs
        this.showSearchBar = false;
        this.toggleLoginLinks(null, true);
        this.toggleSecondNavLink(null, true);
        // If window is closed, then open with animation
        this.showRegionSelector = true;
        setTimeout(
          () => this.$refs.regionSecondLevelCnt.classList.add("expand"),
          10
        );
      }
    },
    toggleLoginLinks: function (event, closeFlag) {
      if (this.showLoginLinks === true || closeFlag === true) {
        // Soft animated close of the present window
        this.$refs.loginSecondLevelCnt.classList.remove("expand");
        setTimeout(() => (this.showLoginLinks = false), this.animationDuration);
      } else {
        // Before open close all other tabs
        this.showSearchBar = false;
        this.toggleRegionSelector(null, true);
        this.toggleSecondNavLink(null, true);
        // If window is closed, then open with animation
        this.showLoginLinks = true;
        setTimeout(
          () => this.$refs.loginSecondLevelCnt.classList.add("expand"),
          10
        );
      }
    },
    toggleSecondNavLink: function (event, closeFlag) {
      if (event !== null) {
        // If the device is mobile and the event is of type mouseover stop de function
        if (this.viewportWidth < 1024 && event.type === "mouseover") {
          return;
        } else {
          // Get clicked button id
          const clickedLinkId = event.target.id;

          // Loop through all links and toggle the one selected and close the others
          this.secondNavMenu.forEach((link) => {
            if (link.id === clickedLinkId) {
              if (link.toggle === true || closeFlag === true) {
                // Soft animated close of the present window
                document
                  .querySelector(`nav#${link.id}`)
                  .classList.remove("expand");
                setTimeout(() => (link.toggle = false), this.animationDuration);
              } else {
                // Before open close all other tabs
                this.toggleSearchBar(null, true);
                this.toggleRegionSelector(null, true);
                this.toggleLoginLinks(null, true);
                // If window is closed, then open with animation
                link.toggle = true;
                setTimeout(
                  () =>
                    document
                      .querySelector(`nav#${link.id}`)
                      .classList.add("expand"),
                  10
                );
              }
            } else {
              // Soft close all the tabs that are not the one clicked
              document
                .querySelector(`nav#${link.id}`)
                .classList.remove("expand");
              setTimeout(() => (link.toggle = false), this.animationDuration);
            }
          });
        }
      } else {
        this.secondNavMenu.forEach((link) => {
          // If there's no event then close all the tabs of this container
          document.querySelector(`nav#${link.id}`).classList.remove("expand");
          setTimeout(() => (link.toggle = false), this.animationDuration);
        });
      }
    },
    closeAllTabs: function () {
      // Close all the tabs that aren't second nav menu
      this.toggleNavMenu(null, true);
      this.toggleLoginLinks(null, true);
      this.toggleRegionSelector(null, true);
      this.toggleSearchBar(null, true);
      this.toggleSecondNavLink(null, true);
    },
    searchRedirect: function () {
      const formattedString = this.searchInput.replaceAll(" ", "+");
      // Close the searchbar
      this.showSearchBar = false;
      window.open(
        "https://www.gdx.net/search/site?q=" + formattedString,
        "_blank",
        "noopener,noreferrer"
      );
      // Cancel default action of the button to prevent the form to submit something since only the redirect is needed
      return false;
    },
  },
  created: function () {
    this.countSecondNavLinks();
    this.viewportWidth = document.documentElement.clientWidth;
  },
  mounted: function () {
    // Add document event listener to close all tabs when user clicks anywhere outside the header
    document.addEventListener("click", (event) => {
      const headerElement = document.querySelector("#gdx-header");
      if (!headerElement.contains(event.target)) {
        this.closeAllTabs();
      }
    });
  },
});
