/**
 *  Reinitialize component.
 *  Search for instance using componentId.
 *  If instance found, destroy and reinitialize only that instance.
 *  If instance is new, initialize new instance and add it to the end of array
 *
 *  @example
 *  @param {String} componentId - Id of the component that needs rendering
 *  @param {Array<VueModels>} componentArray - Array of Vue Models storing the component type
 *  @param {function} initializeCallback - Callback function to initialize single instance
 */
const reinitializeComponent = (componentId, componentArray, initializeCallback) => {
    if (componentId && componentArray) {
        const instanceIndex = componentArray.findIndex((instance) => instance.$el.id === componentId);
        const componentElement = document.getElementById(componentId);

        if (instanceIndex !== -1) {
            componentArray[instanceIndex].$destroy();
            initializeCallback(componentElement, instanceIndex);
        } else {
            initializeCallback(componentElement, componentArray.length);
        }
    }
}

export {
    reinitializeComponent
};