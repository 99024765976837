import { reinitializeComponent } from "./utils/render.js";

// Array of Vue Models of the Hero Animation instances
const vmHeroAnimations = [];

// Function used to initialize a single Vue instance
const initializeSingleHeroAnimation = (element, index) => {
  vmHeroAnimations[index] = new Vue({
    el: `#${element.id}`,
    data: {
      slidesIndex: 1,
      isPlaying: true,
      numberOfSlides: 0,
      animationDuration: 4000,
      intervalElement: null,
    },
    methods: {
      countSlides: function () {
        // Get the number of slides inside one vue instance of this type of component
        this.numberOfSlides = document.querySelectorAll(
            `#${element.id} .hero-animation__slide-container`
        ).length;
      },
      transitionSlides: function (currentSlideIndex, nextSlideIndex) {
        // Get DOM elements of the slides and control indicators
        const currentSlide = document.getElementById(
            `${element.id}-slide-${currentSlideIndex}`
        );
        const nextSlide = document.getElementById(
            `${element.id}-slide-${nextSlideIndex}`
        );
        const currentIndicator = document.getElementById(
            `${element.id}-btn-${currentSlideIndex}`
        );
        const nextIndicator = document.getElementById(
            `${element.id}-btn-${nextSlideIndex}`
        );

        // Apply and remove classes to transition the slides and control indicators
        currentSlide.classList.remove("slide-enter");
        currentSlide.classList.add("slide-leave");

        nextSlide.classList.remove("slide-leave");
        nextSlide.classList.add("slide-enter");

        currentIndicator.classList.remove("active");
        nextIndicator.classList.add("active");
      },
      chooseSlide: function (event) {
        const slideNumberChosen = parseInt(
            event.target.dataset.indexNumber,
            10
        );

        this.transitionSlides(this.slidesIndex, slideNumberChosen);
        this.slidesIndex = slideNumberChosen;
      },
      nextSlide: function () {
        // Transition slides, check if the last one has been reached in order to asign the next one as the first
        this.transitionSlides(
            this.slidesIndex,
            this.slidesIndex < this.numberOfSlides ? this.slidesIndex + 1 : 1
        );

        // Increment by one the slides index
        if (this.slidesIndex < this.numberOfSlides) {
          this.slidesIndex++;
        } else {
          this.slidesIndex = 1;
        }
      },
      playSlides: function () {
        // Begin the animation loop with a timer determined by the animation duration variable
        this.intervalElement = setInterval(
            this.nextSlide,
            this.animationDuration
        );
      },
      stopSlides: function () {
        // To stop the timer clear the interval variable
        clearInterval(this.intervalElement);
        this.intervalElement = null;
      },
      playPauseSlides: function () {
        this.isPlaying === true ? this.stopSlides() : this.playSlides();
        this.isPlaying = !this.isPlaying;
      },
      playFromStart: function () {
        // Get DOM elements of the first slide and indicator
        const firstSlide = document.getElementById(`${element.id}-slide-1`);
        const firstIndicator = document.getElementById(`${element.id}-btn-1`);

        // Add slide enter and active class for the slide and indicator
        firstSlide.classList.add("slide-enter");
        firstIndicator.classList.add("active");

        // Begin the normal loop of slides
        this.playSlides();
      },
    },
    created: function () {
      // When component html loads count the number of slides and build array of state
      this.countSlides();
    },
    mounted: function () {
      // Begin the slides transition when the component is mounted
      this.playFromStart();
    },
    beforeDestroy: function () {
      this.stopSlides();
    },
  });
}

// Function used to initialize the Vue JS component
const initializeHeroAnimation = (event) => {
  const heroAnimationComponents = document.querySelectorAll(
      ".hero-animation__outer-container"
  );

  //initialize media-container Vue JS instances
  heroAnimationComponents.forEach((element, index) => {
    initializeSingleHeroAnimation(element, index);
  });
};

// When the page DOM is loaded proceed to initialize the Vue Model instance for the component
window.addEventListener("DOMContentLoaded", initializeHeroAnimation);

// Listener to catch the custom event triggered when the component is reloaded in the authoring page of BR
// When this event is triggered the array of Vue Models of the component in the page are destroyed and reinitialized
document.addEventListener("brContainerRedraw-heroAnimation", (e) => {
  reinitializeComponent(e.componentId, vmHeroAnimations, initializeSingleHeroAnimation)
});
