import { reinitializeComponent } from "./utils/render.js";

// Array of vue media container instances
const vmMediaContainers = [];

// Function used to initialize a single Vue instance
const initializeSingleMediaContainer = (element, index) => {
  vmMediaContainers[index] = new Vue({
    el: `#${element.id}`,
    data: {
      showVideo: false,
    },
    methods: {
      playVideo: function () {
        this.showVideo = !this.showVideo;
        this.$refs.videoIframe.contentWindow.postMessage(
            '{"event":"command","func":"playVideo"}',
            "https://www.youtube.com"
        );
      },
    },
  });
}

// Function used to initialize the Vue JS components
const initializeMediaContainer = (event) => {
  const mediaContainerComponents = document.querySelectorAll(
    ".media-container__outer-container"
  );

  //initialize media-container Vue JS instances
  mediaContainerComponents.forEach((element, index) => {
    initializeSingleMediaContainer(element, index);
  });
};

// When the page DOM is loaded proceed to initialize the Vue Model instance for the component
window.addEventListener("DOMContentLoaded", initializeMediaContainer);

// Listener to catch the custom event triggered when the component is reloaded in the authoring page of BR
// When this event is triggered the array of Vue Models of the component in the page are destroyed and reinitialized
document.addEventListener("brContainerRedraw-mediaContainer", (e) => {
  reinitializeComponent(e.componentId, vmMediaContainers, initializeSingleMediaContainer);
});
