import { reinitializeComponent } from "./utils/render.js";

// Array of Vue Models of the Tabbed Profiles instances
const vmTabbedProfiles = [];

// Function used to initialize a single Vue instance
const initializeSingleTabbedProfiles = (element, index) => {
  vmTabbedProfiles[index] = new Vue({
    el: `#${element.id}`,
    data: {
      showTabbedProfiles: [],
    },
    methods: {
      countTabbedProfiles: function () {
        // Get the number of tabbed profiles cards inside one vue instance of this type of component
        const numberOfTabbedProfiles = document.querySelectorAll(
          `#${element.id} > .tabbed-profiles__card-container`
        ).length;

        // Fill the array to keep track of opened and closed tabs
        for (let i = 0; i < numberOfTabbedProfiles; i++) {
          let tabObj = {
            id: `${element.id}-tp-${i + 1}`,
            toggle: false,
          };
          this.showTabbedProfiles.push(tabObj);
        }
      },
      toggleTabbedProfile: function (event) {
        this.showTabbedProfiles.forEach((tabbedProfile) => {
          const descCntSelector = `.tabbed-profiles__card-container#${tabbedProfile.id} > .tabbed-profiles__description-container`;
          if (tabbedProfile.id === event.target.parentNode.parentNode.id) {
            if (tabbedProfile.toggle === true) {
                // If tab open then close it
                tabbedProfile.toggle = false;
            } else {
              // If tab is closed, then open it
              tabbedProfile.toggle = true;
            }
          } else {
            // Close all the tabs that aren't the one clicked
              tabbedProfile.toggle = false;
          }
        });
      },
    },
    created: function () {
      // When component html loads count the number of tabbed profiles cards on it
      this.countTabbedProfiles();
    },
    mounted: function () {
      // Open the first tab when component is mounted
      const firstTabSelector = `#${element.id} .tabbed-profiles__title-container#${element.id}-tc-1 > h5`;
      document.querySelector(firstTabSelector).click();
    }
  });
}

// Function used to initialize the Vue JS components
const initializeTabbedProfiles = (event) => {
  const tabbedProfilesComponents = document.querySelectorAll(
      ".tabbed-profiles__outer-container"
  );

  //initialize media-container Vue JS instances
  tabbedProfilesComponents.forEach((element, index) => {
    initializeSingleTabbedProfiles(element, index)
  });
};

// When the page DOM is loaded proceed to initialize the Vue Model instance for the component
window.addEventListener("DOMContentLoaded", initializeTabbedProfiles);

// Listener to catch the custom event triggered when the component is reloaded in the authoring page of BR
// When this event is triggered the array of Vue Models of the component in the page are destroyed and reinitialized
document.addEventListener("brContainerRedraw-tabbedProfiles", (e) => {
  reinitializeComponent(e.componentId, vmTabbedProfiles, initializeSingleTabbedProfiles);
});
